import { NextFontWithVariable } from "next/dist/compiled/@next/font";
import { COMPANY } from "@/data/types/companies";
import { THEME } from "@/themes/types";

export interface SiteInfo {
  domainName: string;
  siteName: string;
  siteTheme: THEME;
  siteEmail: string;
  siteWhiteLogo: string;
  siteBlackLogo32: string;
  siteBlackLogo28?: string;
  siteLogoMobile?: string;
  siteBlackLogoFooter?: string;
  favicon: string;
  indexedPaths: string[];
  companies: COMPANY[];
  isFreeTrial: boolean;
  fonts?: NextFontWithVariable[];
  scanDomain?: string;
}

export enum SiteName {
  QRCREATOR = "QR Creator",
  FASTQRGENERATOR = "Fast QR Generator",
  QRNOW = "QR Now",
  ONLINEQRCODE = "onlineQRcode",
}
